import gql from 'graphql-tag'

export default {
    allJobArticles : gql`
        query {
            allJobArticles(where: { status: published }) {
                id
                title
                timestamp
                body2
                image {
                    publicUrl
                }
                status
            }
        }
    `,
    allNewsArticles : gql`
        query {
            allNewsArticles(where: { status: published }) {
                id
                title
                timestamp
                image {
                    publicUrl
                }
                status
            }
        }
    `,
    newsArticle : (id) => {
        return gql`
            query {
                NewsArticle(where: { id: ${id} }) {
                    id
                    title
                    timestamp
                    status
                    body1
                    body2
                    image {
                        publicUrl
                    }
                    updatedAt
                    createdAt
                }
            }
        `
    },
    jobArticle : (id) => {
        return gql`
            query {
                JobArticle(where: { id: ${id} }) {
                    id
                    title
                    timestamp
                    status
                    body1
                    updatedAt
                    createdAt
                }
            }
        `
    }
}
